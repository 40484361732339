<template>
  <b-navbar
    v-on-scroll="handleScroll"
    v-on-window-change="activeMode"
    class="fixed-top"
    toggleable="lg"
    type="dark"
    :class="{
      'header-white': headerMode
    }"
  >
    <b-container>
      <b-navbar-brand to="/">
        <b-img
          v-if="activeMode"
          :src="require('@/assets/imgs/logos/red-logo.svg')"
        ></b-img>
        <b-img v-else :src="require('@/assets/imgs/logos/logo.svg')"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mx-auto">
          <b-nav-item
            v-for="link in links"
            :key="link.index"
            class="px-3"
            :to="link.path"
            >{{ link.text }}</b-nav-item
          >
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item
            class="nav-link--locale text-center"
            @click="changeLanguage"
          >
            <span>{{
              locale === "en" ? $t("label.arabic") : $t("label.english")
            }}</span>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      locale: Cookies.get("language"),
      headerMode: false, // transparent => false & white => true
      activeMode: window.innerWidth <= 784 ? true : false,
      links: [
        {
          text: this.$t("link.who_we_are"),
          path: "/about-us"
        },
        {
          text: this.$t("link.our_companies"),
          path: "/our-companies"
        },
        {
          text: this.$t("link.sharing_details"),
          path: "/sharing-details"
        },
        {
          text: this.$t("link.investment_invitation"),
          path: "/investments-invitation"
        },
        {
          text: this.$t("link.money_details"),
          path: "/money-details"
        }
      ]
    };
  },

  methods: {
    changeLanguage() {
      this.locale = this.locale === "en" ? "ar" : "en";
      Cookies.set("language", this.locale);
      this.$nextTick(() => this.$store.commit("SET_LANG", this.locale));
    },
    handleScroll(event, el) {
      if (window.scrollY >= 80) {
        el.classList.add("header--active");
        this.activeMode = true;
      } else {
        if (window.innerWidth <= 784) {
          el.classList.add("header--active");
        } else {
          el.classList.remove("header--active");
          this.activeMode = false;
        }
      }
    }
  }
};
</script>
